<template>
  <MyView :meta="{ viewClass: 'view-chat', inFx: 'rightIn' }" ref="MyView" :style="`--cbxh:${chatBoxHeight}px`">
    <ViewHeader icon="arrowLeft" @back="goBack">
      <div slot="center" class="view-chat-user">
        <div class="view-chat-avatar">
          <UserAvatar size="36px" :user="user" />
        </div>
        <div grid>
          <span ellipsis>@{{ user.user }}</span>
        </div>
      </div>
      <div slot="right" class="flex-center">
        <a class="view-header-button"><UserCreditsBtn /></a>
        <HamburgerBtn />
      </div>
    </ViewHeader>
    <ViewContent @scroll.native="scrolling">
      <div class="view-chat-content">
        <div class="view-chat-main main">
          <div class="view-chat-list">
            <ViewMoreBtn :morepage="morepage" :loading="loading" @click="getMessages(page + 1)" />
            <MessagesChatCard v-for="(item, index) in messages.slice().reverse()" :key="index" :item="item" :eachDate="eachDate" />
          </div>
        </div>
      </div>
    </ViewContent>
    <MessagesChatBoxFiles :filesPreview="filesPreview" v-if="filesPreview && filesPreview.length > 0" @delSlide="deleteFile" />
    <MessagesChatBox :user="$route.query.messages" @files="handleFiles" @resize="chatBoxResize" />
    <div class="chatViewMenu">
      <a class="button" @click="goBack"><Icon name="chevron-left" /></a>
    </div>
  </MyView>
</template>

<script>
let currentDate = null;
export default {
  components: {
    MyView: () => import("../../views/View.vue"),
    ViewHeader: () => import("../view/ViewHeader.vue"),
    ViewContent: () => import("../view/ViewContent.vue"),
    HamburgerBtn: () => import("../home/HamburgerBtn.vue"),
    UserCreditsBtn: () => import("../user/UserCreditsBtn.vue"),
    ViewMoreBtn: () => import("../widgets/ViewMoreBtn.vue"),
    MessagesChatCard: () => import("./MessagesChatCard.vue"),
    MessagesChatBox: () => import("./MessagesChatBox.vue"),
    MessagesChatBoxFiles: () => import("./MessagesChatBoxFiles.vue"),
  },
  data: function() {
    return {
      loading: false,
      files: [],
      filesPreview: [],
      chatBoxHeight: 0,
    };
  },
  methods: {
    goBack: function() {
      this.$refs.MyView.close(() => {
        this.setQuery({ messages: "wall" });
      });
    },
    handleFiles: function({ files, preview, chatBoxHeight }) {
      this.files = files;
      this.filesPreview = preview;
      this.chatBoxHeight = chatBoxHeight;
    },
    deleteFile: function(index) {
      this.filesPreview.splice(index, 1);
      this.files.splice(index, 1);
    },
    chatBoxResize: function({ height }) {
      this.chatBoxHeight = height;
    },
    getMessages: async function(page) {
      this.loading = true;
      let query = `${this.q ? `&q=${this.q}` : ""}&user=${this.$route.query.messages}`;
      const callback = () => setTimeout(() => this.isLoading(false), 500);
      const error = (error) => this.ialert.go({ title: "Error", message: error });
      this.$store.dispatch("getMessages", { page, limit: this.limit, query, callback, error });
    },
    scrollToBottom: function() {
      setTimeout(() => {
        const element = document.querySelector(".view-chat .main-view-wrap-content");
        if (element && !this.loading) {
          element.scrollTop = element.scrollHeight;
        } else {
          this.scrollToBottom();
        }
      }, 100);
    },
    scrolling: function($event) {
      this.$scroller.isTopScroll($event, () => {
        if (!this.loading && this.morepage) {
          this.getMessages(this.page + 1);
        }
      });
    },
    getDate: function(date) {
      let time = date.split("T");
      return time[0];
    },
    eachDate: function(date) {
      if (currentDate !== date) {
        currentDate = date;
        return currentDate;
      } else {
        currentDate = date;
        return false;
      }
    },
  },
  computed: {
    user: function() {
      return this.$store.getters.messages.user;
    },
    messages: function() {
      return this.$store.getters.messages.messages;
    },
    page: function() {
      return this.$store.getters.messages.page;
    },
    morepage: function() {
      return this.$store.getters.messages.hasmore;
    },
    newMessage: function() {
      return this.$store.getters.messages.newCount;
    },
  },
  beforeMount: function() {
    currentDate = null;
    const { _id } = this.user || {};
    const { messages } = this.$route.query;
    if (this.user._id !== messages) {
      this.getMessages(1);
    } else if (!this.messages || (!this.messages.length && messages !== _id)) {
      this.getMessages(1);
    }
    this.scrollToBottom();
  },
  destroyed: function() {
    currentDate = null;
  },
  mounted: function() {
    currentDate = null;
  },
  watch: {
    newMessage: function() {
      this.scrollToBottom();
    },
  },
};
</script>

<style lang="scss">
.view-chat {
  z-index: 12;
  &-user {
    display: flex;
    align-items: center;
  }
  &-avatar {
    padding: 0 $mpadding/2 0 0;
  }
  &-list {
    padding: $mpadding;
    margin-bottom: 49px;
  }
}
</style>
